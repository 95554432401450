import React from "react";
import "./../../layoutComponent/Navbar.css";
import ModalImage from "react-modal-image";
import "../lineComponent/LineStyle.css";
import { useTranslation } from "react-i18next";
function CerPage() {
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };
  return (
    <div className="container mx-auto">
      <div className="container mx-auto">
        <div className="p-3 Font1 font-bold xl:text-[30px] xl:text-center text-[25px] text-center">
          {t("certificates")}
        </div>
        <div className="grid 2xl:grid-cols-3 sm:grid-cols-3 ">
          <div className="">
            <ModalImage
              small={
                "https://bae-image-videos.s3.ap-southeast-1.amazonaws.com/image_iso/Cer+IATF+16949+FAC.2-1.png"
              }
              large={
                "https://bae-image-videos.s3.ap-southeast-1.amazonaws.com/image_iso/Cer+IATF+16949+FAC.2-1.png"
              }
              alt=""
              className="2xl:w-[400px] 2xl:h-[600px] w-[200px] h-[300px] p-5 hover:shadow-2xl duration-500 ease-in mx-auto"
            />
          </div>
          <div className="">
            <ModalImage
              small={
                "https://bae-image-videos.s3.ap-southeast-1.amazonaws.com/image_iso/Cer+IATF+16949+FAC1-1.png"
              }
              large={
                "https://bae-image-videos.s3.ap-southeast-1.amazonaws.com/image_iso/Cer+IATF+16949+FAC1-1.png"
              }
              alt=""
              className="2xl:w-[400px] 2xl:h-[600px] w-[200px] h-[300px] p-5 hover:shadow-2xl duration-500 ease-in mx-auto"
            />
          </div>
          <div className="">
            <ModalImage
              small={
                "https://bae-image-videos.s3.ap-southeast-1.amazonaws.com/image_iso/Certificate+ISO+14001-1.png"
              }
              large={
                "https://bae-image-videos.s3.ap-southeast-1.amazonaws.com/image_iso/Certificate+ISO+14001-1.png"
              }
              alt=""
              className="2xl:w-[400px] 2xl:h-[600px] w-[200px] h-[300px] p-5 hover:shadow-2xl duration-500 ease-in mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CerPage;
